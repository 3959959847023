.tables-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
}
.table-box {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    font-size:25px
}
.table-box {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.table-box:hover {
        transform: scale(1.1);
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
    }
.table-dummy {
    background: linear-gradient(45deg, #6a11cb, #2575fc);
    border: 2px dashed #ffffff;
    font-style: italic;
}
.popup-tables{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px); /* Adds blur to the background */
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
.popup-content-tables {
    background: white;
    border-radius: 15px;
    padding: 20px 30px;
    width: 350px;
    max-width: 90%;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    text-align: center;
    position: relative;
    animation: slideIn 0.3s ease-in-out;
}
@keyframes slideIn {
    from {
        transform: scale(0.9);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}
.add-new{
    font-size:25px;
    border:none
}
.popup-content-tables h3 {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #333;
}
.popup-content-tables ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
    max-height: 150px;
    overflow-y: auto; /* Scroll if items exceed the space */
}
.popup-content-tables ul li {
        padding: 5px 10px;
        margin: 5px 0;
        background: #f9f9f9;
        border-radius: 5px;
        color: #555;
        border: 1px solid #ddd;
    }
.popup-content-tables button {
    background: #ff6f61;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
    font-size: 0.9em;
}
.popup-content-tables button:hover {
        background: #ff4d4d;
    }
.popup-content-tables button:first-of-type {
        background: #4caf50; /* Add Items button */
    }
.popup-content-tables button:first-of-type:hover {
            background: #43a047;
        }
.popup-content-tables::-webkit-scrollbar {
    width: 6px;
}
.popup-content-tables::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 3px;
}
.tabs-container {
    margin-top:30px;
    margin-left:20px;
    display: flex;
    margin-bottom: 20px;
}
.tab {
    padding: 12px 20px;
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-right: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
}
.tab:hover {
        background-color: #ddd;
    }
.tab.active {
        background-color: #c84347;
        color: white;
    }
.tab:focus {
        outline: none;
    }
.search-container-tables {
    display: flex;
    margin-bottom: 20px;
    margin-left:20px
}
.search-container-tables input {
        padding: 12px 20px;
        font-size: 16px;
        border: 2px solid #ccc;
        border-radius: 5px;
        width: 250px;
        transition: all 0.3s ease;
    }
.search-container-tables input::placeholder {
        color:black
    }
.search-container-tables input:focus {
            border-color: #4CAF50;
            outline: none;
        }
.add-new-table-btn {
    padding: 12px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 20px;
    display: block;
    margin: 0 auto;
}
.add-new-table-btn:hover {
        background-color: #45a049;
    }
.add-table-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
}
.modal-content input, .modal-content select {
        padding: 12px;
        width: 100%;
        margin: 10px 0;
        border: 2px solid #ccc;
        border-radius: 5px;
    }
.modal-content button {
        padding: 10px 15px;
        margin: 10px 0;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
.modal-content button:hover {
            background-color: #45a049;
        }
.modal-content button:focus {
            outline: none;
        }
.addnew-table input {
    color: black;
    height:30px;
    border-radius:5px;
    border:1px solid silver;
    padding:3px
}
.addnew-table input::placeholder{
    color:black
}

@media screen and (max-width: 768px) {
    .tables-container {
        gap: 10px;
        padding: 10px;
    }

    .table-box {
        width: 150px;
        height: 150px;
        font-size: 20px;
    }

    .popup-content-tables {
        width: 95%;
    }

    .tab {
        padding: 10px;
        font-size: 14px;
    }

    .search-container-tables input {
        width: 100%;
    }

    .modal-content {
        width: 95%;
    }
}

@media screen and (max-width: 480px) {
    .table-box {
        width: 120px;
        height: 120px;
        font-size: 18px;
    }

    .popup-content-tables {
        padding: 15px;
    }

    .modal-content {
        padding: 15px;
    }

    .tab {
        padding: 8px;
        font-size: 12px;
    }

    .add-new-table-btn {
        font-size: 14px;
        padding: 10px;
    }
}