.h-pos-details-page {
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-height: 80vh;  /* Set a maximum height for the form */
    overflow-y: auto;  /* Make the entire form scrollable */
  }
  
  .h-pos-section {
    margin-bottom: 20px;
  }
  
  .h-pos-form-group {
    margin-bottom: 10px;
  }
  
  .h-pos-form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .h-pos-form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .h-pos-checkbox-group {
    display: flex;
    align-items: center;
  }
  
  .h-pos-checkbox-group input {
    margin-right: 10px;
  }
  
  /* Optional: Add some styling for scrollbars */
  .h-pos-details-page::-webkit-scrollbar {
    width: 8px;
  }
  
  .h-pos-details-page::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
  
  .h-pos-details-page::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  