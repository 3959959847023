.dashboard-container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  border: 1px solid silver;
  background-color: white;
  overflow-y: auto;
  height: 100vh;
  scrollbar-width: none;
}

.img-cont {
  background-image: url('https://avatars.mds.yandex.net/i?id=6463fba27cd55498e50e13ce0bb2dfe0_l-5235574-images-thumbs&ref=rim&n=13&w=2400&h=1350');
  background-position: center;
  background-size: cover;
  border-radius: 10px;
}

.img-cont-overlay {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.327);
  border: 1px solid silver;
  border-radius: 10px;
}

.dash-text {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 20px;
}

.dash-text-main {
  font-size: 35px;
  font-weight: bold;
  color: #c84347;
  margin-bottom: 10px;
}

.dash-text2 {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}

.dash-text-main2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.stats-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 30px;
  margin-bottom: 10px;
}

.stat-card {
  background-color: white;
  border: 1px solid #c84347;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.stat-card h2, .stat-card div {
  color: black;
  font-size: 1rem;
}

.charts-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

.chart-box {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #c84347;
}

.chart-box div {
  font-size: 1.3rem;
  color: #000000;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .stats-container {
    grid-template-columns: 1fr 1fr;
  }

  .charts-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .stats-container {
    grid-template-columns: 1fr;
  }
  
  .charts-container {
    grid-template-columns: 1fr;
  }
}
