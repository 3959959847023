/* PriceListPage.css */

.h-pos-price-list-page {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .h-pos-price-list-table{
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .-pos-price-list-table th,
  .h-pos-price-list-table td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .h-pos-price-list-table th {
    background-color: #f2f2f2;
  }
  
  .h-pos-price-list-table tr:nth-child(even) {
    background-color: #fafafa;
  }
  
  .h-pos-price-list-table td input[type="checkbox"] {
    width: 16px;
    height: 16px;
  }
  
  .h-pos-price-list-table td {
    font-size: 12px;
  }
  