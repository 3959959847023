.receipts-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
}

.receipt-box {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    font-size: 25px
}

.receipt-box {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

    .receipt-box:hover {
        transform: scale(1.1);
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
    }

.receipt-dummy {
    background: linear-gradient(45deg, #6a11cb, #2575fc);
    border: 2px dashed #ffffff;
    font-style: italic;
}

.popup-receipts {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px); /* Adds blur to the background */
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.popup-content-receipts {
    background: white;
    border-radius: 15px;
    padding: 20px 30px;
    width: 350px;
    max-width: 90%;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    text-align: center;
    position: relative;
    animation: slideIn 0.3s ease-in-out;
}

@keyframes slideIn {
    from {
        transform: scale(0.9);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

.add-new {
    font-size: 25px;
    border: none
}

.popup-content-receipts h3 {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #333;
}

.popup-content-receipts ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
    max-height: 150px;
    overflow-y: auto; /* Scroll if items exceed the space */
}

    .popup-content-receipts ul li {
        padding: 5px 10px;
        margin: 5px 0;
        background: #f9f9f9;
        border-radius: 5px;
        color: #555;
        border: 1px solid #ddd;
    }

.popup-content-receipts button {
    background: #ff6f61;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
    font-size: 0.9em;
}

    .popup-content-receipts button:hover {
        background: #ff4d4d;
    }

    .popup-content-receipts button:first-of-type {
        background: #4caf50; /* Add Items button */
    }

        .popup-content-receipts button:first-of-type:hover {
            background: #43a047;
        }

.popup-content-receipts::-webkit-scrollbar {
    width: 6px;
}

.popup-content-receipts::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 3px;
}

.tabs-container {
    margin-top: 30px;
    margin-left: 20px;
    display: flex;
    margin-bottom: 20px;
}

.tab {
    padding: 12px 20px;
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-right: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
}

    .tab:hover {
        background-color: #ddd;
    }

    .tab.active {
        background-color: #c84347;
        color: white;
    }

    .tab:focus {
        outline: none;
    }

.search-container-receipts {
    display: flex;
    margin-bottom: 20px;
    margin-left: 20px
}

    .search-container-receipts input {
        padding: 12px 20px;
        font-size: 16px;
        border: 2px solid #ccc;
        border-radius: 5px;
        width: 250px;
        transition: all 0.3s ease;
    }

        .search-container-receipts input::placeholder {
            color: black
        }

        .search-container-receipts input:focus {
            border-color: #4CAF50;
            outline: none;
        }

.add-new-receipt-btn {
    padding: 12px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 20px;
    display: block;
    margin: 0 auto;
}

    .add-new-receipt-btn:hover {
        background-color: #45a049;
    }

.add-receipt-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
}

    .modal-content input, .modal-content select {
        padding: 12px;
        width: 100%;
        margin: 10px 0;
        border: 2px solid #ccc;
        border-radius: 5px;
    }

    .modal-content button {
        padding: 10px 15px;
        margin: 10px 0;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

        .modal-content button:hover {
            background-color: #45a049;
        }

        .modal-content button:focus {
            outline: none;
        }

.addnew-receipt input {
    color: black;
    height: 30px;
    border-radius: 5px;
    border: 1px solid silver;
    padding: 3px
}

    .addnew-receipt input::placeholder {
        color: black
    }
