/* Location Page Styles */
.h-pos-location-page {
    padding: 20px;
}

.h-pos-location-page h2 {
    margin-bottom: 20px;
    font-size: 20px; /* Adjusted heading size */
    color: #333;
}

.h-pos-location-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}

.h-pos-location-table th,
.h-pos-location-table td {
    padding: 8px; /* Adjusted padding for a more compact table */
    border: 1px solid #ddd;
    font-size: 14px; /* Smaller font size for the table */
}

.h-pos-location-table th {
    background-color: #f4f4f4;
}

.h-pos-location-table td {
    text-align: center; /* Center aligned text */
}
