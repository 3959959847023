.h-counter-details-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  box-sizing: border-box;
}

.h-counter-section {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  width: 100%;
}

.h-counter-section h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 600;
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 10px;
  word-break: break-word;
}

.h-counter-form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.h-counter-form-group label {
  margin-bottom: 5px;
  font-weight: 500;
  color: #555;
  width: 100%;
}

.h-counter-form-group input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}

.h-counter-form-group input:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.3);
}

.h-counter-form-group input[readonly] {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.h-counter-form-group button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s;
  margin-top: 10px;
  align-self: flex-start;
  box-sizing: border-box;
}

.h-counter-form-group button:hover {
  opacity: 0.9;
}

/* Style for counter name fields to make them distinct */
.h-counter-form-group:has(input[type="text"][value*="Counter"]) {
  border-left: 3px solid #e0e0e0;
  padding-left: 10px;
}

/* Error styles */
.h-counter-form-group.error input {
  border-color: #ff5252;
}

.h-counter-form-group .error-message {
  color: #ff5252;
  font-size: 12px;
  margin-top: 4px;
}

/* Animation for updates */
@keyframes highlight {
  0% { background-color: rgba(74, 144, 226, 0.2); }
  100% { background-color: transparent; }
}

.field-updated {
  animation: highlight 1.5s ease-out;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .h-counter-details-page {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .h-counter-section {
    flex: 1;
    min-width: 300px;
  }
}

/* Small screen specific styles */
@media (max-width: 767px) {
  .h-counter-details-page {
    padding: 10px;
    gap: 15px;
    overflow-x: hidden;
  }
  
  .h-counter-section {
    padding: 12px;
    margin-bottom: 15px;
    border-radius: 6px;
    overflow: hidden;
  }
  
  .h-counter-section h2 {
    font-size: 1.1rem;
    padding-bottom: 8px;
    margin-bottom: 12px;
  }
  
  .h-counter-form-group {
    margin-bottom: 12px;
  }
  
  .h-counter-form-group label {
    font-size: 13px;
    white-space: normal;
    word-break: break-word;
  }
  
  .h-counter-form-group input {
    padding: 8px;
    font-size: 14px;
  }
  
  .h-counter-form-group button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    align-self: center;
    text-align: center;
  }
  
  /* Simplified border for counter fields on mobile */
  .h-counter-form-group:has(input[type="text"][value*="Counter"]) {
    border-left: 2px solid #e0e0e0;
    padding-left: 6px;
  }
}

/* Extra small screens */
@media (max-width: 375px) {
  .h-counter-details-page {
    padding: 8px;
    gap: 12px;
  }
  
  .h-counter-section {
    padding: 10px;
  }
  
  .h-counter-section h2 {
    font-size: 1rem;
  }
  
  .h-counter-form-group * {
    max-width: 100%;
    overflow-wrap: break-word;
  }
}