.feedback-container {
    padding: 20px;
    max-width: 100%;
    overflow-x: auto;
  }
  
  .feedback-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
    /* ThemeColor will be applied inline from the component */
  }
  
  .feedback-table-container {
    overflow-x: auto;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    scrollbar-width: none;
  }
  
  .feedback-table {
    width: 100%;
    border-collapse: collapse;
    min-width: 800px;
  }
  
  .feedback-table th {
    padding: 12px 15px;
    text-align: left;
    font-weight: 600;
   
    color: white;
    position: sticky;
    top: 0;
  }
  
  .feedback-table td {
    padding: 10px 15px;
   
  }
  
  .feedback-table tr:last-child td {
    border-bottom: none;
  }
  
  /* /* .feedback-table tr:nth-child(even) {
    background-color: #f9f9f9;
  } */
  
  .feedback-table tr:hover {
    background-color: #f1f1f1;
  } 
  
  .feedback-ratings {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .feedback-ratings p {
    margin: 0;
  }
  
  .feedback-loading, .feedback-error {
    padding: 20px;
    text-align: center;
    font-size: 16px;
  }
  
  .feedback-error {
    color: #d32f2f;
  }
  
  @media (max-width: 768px) {
    .feedback-container {
      padding: 10px;
    }
    
    .feedback-title {
      font-size: 20px;
    }
  }