.ultra-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('https://i.ytimg.com/vi/pqltaoMDoUU/maxresdefault.jpg');
  background-size: cover;
  font-family: 'Arial', sans-serif;
}

.ultra-user-selection, .ultra-password-section {
  background: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 400px;
  animation: fadeIn 0.5s ease-in-out;
}

.ultra-heading {
  color: #ff4a6e;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.ultra-user-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
  justify-content: space-between;
  align-items: center;
}

.ultra-user-item {
  color: #ffffff;
  padding: 12px;
  margin: 8px 0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 18px;
  border: 1px solid grey;
}

.ultra-user-item:hover {
  background: #cacaca;
  transform: scale(1.05);
}

.ultra-password-input {
  width: 95%;
  padding: 12px;
  margin: 12px 0;
  border: 2px solid #fff2f4;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
}

.ultra-password-input:focus {
  border-color: #ff4a6e;
}
.ultra-button-group{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.ultra-login-button {
  background: #ff4a6e;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.ultra-login-button:hover {
  background: #d03a5b;
  transform: scale(1.05);
}

/* Add these styles to your existing Login.css file */

/* Enhanced styles for the form with email support */
.ultra-password-section {
  width: 90%;
  max-width: 400px;
  transition: all 0.3s ease;
}

.ultra-password-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

/* Add icon support to inputs */
.input-icon-wrapper {
  position: relative;
  width: 100%;
}

.input-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

/* Adjust input padding if using icons */
.ultra-password-input {
  width: 95%;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.ultra-password-input:focus {
  transform: scale(1.02);
}

/* Better button transitions */
.ultra-login-button {
  margin-top: 10px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.ultra-login-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none !important;
}

/* Form element animation */
.ultra-password-section form {
  animation: fadeIn 0.6s ease-in-out;
}

/* Add responsive adjustments */
@media (max-width: 480px) {
  .ultra-password-section {
    width: 95%;
    padding: 20px;
  }
  
  .ultra-heading {
    font-size: 22px;
  }
  
  .ultra-password-input,
  .ultra-login-button {
    font-size: 16px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
