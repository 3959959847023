.h-delivery-mode-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Navbar */
.h-delivery-mode-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

.h-delivery-mode-navbar h2 {
  color: #C84347;
  margin: 0;
  font-size: 1.5rem;
}

.h-delivery-mode-controls {
  display: flex;
  gap: 10px;
}

.h-delivery-mode-controls input[type="text"] {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 200px;
}

/* Modal */
.h-delivery-mode-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.h-delivery-mode-modal-content {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Modal Heading */
.h-delivery-mode-modal-content h3 {
  color: #C84347;
  margin-top: 0;
  margin-bottom: 20px;
}

/* Form Group */
.h-delivery-mode-form-group {
  margin-bottom: 16px;
}

.h-delivery-mode-form-group label {
  display: block;
  margin-bottom: 8px;
  color: #333;
}

.h-delivery-mode-form-group input[type="text"] {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.h-delivery-mode-form-group input[type="checkbox"] {
  margin-right: 8px;
}

/* Form Actions */
.h-delivery-mode-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 24px;
}

.h-delivery-mode-form-actions button[type="button"] {
  background-color: #6c757d;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.h-delivery-mode-form-actions button[type="button"]:hover {
  background-color: #5a6268;
}

.h-delivery-mode-form-group input[type="text"]:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(200, 67, 71, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .h-delivery-mode-container {
      padding: 15px;
  }

  .h-delivery-mode-navbar {
      flex-direction: column;
      align-items: flex-start;
  }

  .h-delivery-mode-controls {
      width: 100%;
      margin-top: 10px;
  }

  .h-delivery-mode-controls input[type="text"] {
      width: 100%;
  }

  .h-delivery-mode-modal-content {
      max-width: 90%;
      padding: 20px;
  }

  .h-delivery-mode-form-actions {
      flex-direction: column;
      align-items: stretch;
  }

  .h-delivery-mode-form-actions button[type="button"] {
      width: 100%;
  }
}

@media (max-width: 480px) {
  .h-delivery-mode-navbar h2 {
      font-size: 1.2rem;
      text-align: center;
      width: 100%;
  }

  .h-delivery-mode-controls {
      flex-direction: column;
  }

  .h-delivery-mode-controls input[type="text"] {
      width: 100%;
  }

  .h-delivery-mode-modal-content {
      padding: 16px;
  }

  .h-delivery-mode-form-group input[type="text"] {
      font-size: 0.9rem;
  }
}
