/* TableArea.css */

/* Main wrapper */
.h-table-area-wrapper {
  display: flex;
  min-height: 100vh;
  background-color: #ffffff;
}

@media screen and (max-width: 768px) {
  .h-table-area-wrapper {
    flex-direction: column;
  }
}

/* Sidebar Styles */
.h-sidebar-container {
  width: 260px;
  background-color: #ffffff;
  border-right: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1024px) {
  .h-sidebar-container {
    width: 220px;
  }
}

@media screen and (max-width: 768px) {
  .h-sidebar-container {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
  }
}

/* Table List */
.h-table-list-container {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

@media screen and (max-width: 768px) {
  .h-table-list-container {
    display: flex;
    overflow-x: auto;
    padding: 12px;
  }
}

/* Table Items */
.h-table-item-container {
  padding: 12px 16px;
  margin-bottom: 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
}

.h-table-item-container:hover {
  background-color: #f5f5f5;
}


@media screen and (max-width: 768px) {
  .h-table-item-container {
    flex: 0 0 auto;
    margin-right: 8px;
    margin-bottom: 0;
    min-width: 150px;
  }
}

/* Add Table Button */
.h-add-table-btn {
  margin: 16px;
  padding: 12px;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.2s;
}

.h-add-table-btn:hover {
  opacity: 0.9;
}

@media screen and (max-width: 768px) {
  .h-add-table-btn {
    margin: 12px;
  }
}

/* Main Content */
.h-main-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Navbar */
.h-navbar-container {
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  padding: 16px 24px;
}

.h-navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.h-navbar h2 {
  font-size: 24px;
  margin: 0;
  font-weight: 500;
}

@media screen and (max-width: 1024px) {
  .h-navbar-container {
    padding: 12px 16px;
  }

  .h-navbar h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .h-navbar-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
}

/* Table Info */
.h-table-info-container {
  display: flex;
  gap: 24px;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .h-table-info-container {
    gap: 16px;
  }
}

@media screen and (max-width: 768px) {
  .h-table-info-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}

/* Sub Navbar */
.h-sub-navbar-container {
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 24px;
  display: flex;
  gap: 24px;
  overflow-x: auto;
  white-space: nowrap;
}

.h-sub-nav-tab {
  padding: 16px 8px;
  cursor: pointer;
  color: #666666;
  font-weight: 500;
  transition: all 0.2s;
  border-bottom: 2px solid transparent;
  flex: 0 0 auto;
}

/* .h-sub-nav-tab:hover {
  color: var(--theme-color, #c84347);
} */

/* .h-sub-nav-tab.active {
  color: var(--theme-color, #c84347);
  border-bottom: 2px solid var(--theme-color, #c84347);
} */

@media screen and (max-width: 1024px) {
  .h-sub-navbar-container {
    padding: 0 16px;
  }
}

@media screen and (max-width: 768px) {
  .h-sub-nav-tab {
    padding: 12px 8px;
    font-size: 14px;
  }
}

/* Form Overlay */
.h-form-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.h-form-container {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.h-form-container h3 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
}

.h-form-container input,
.h-form-container select {
  width: 100%;
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
}

.h-form-container input:focus,
.h-form-container select:focus {
  outline: none;
  border-color: var(--theme-color, #c84347);
}

@media screen and (max-width: 768px) {
  .h-form-container {
    padding: 16px;
  }
}

/* Form Actions */
.h-form-actions-container {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 20px;
}

.h-cancel-btn,
.h-save-btn {
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: opacity 0.2s;
}

.h-cancel-btn {
  color: #ffffff;
  border: 1px solid #e0e0e0;
}

.h-save-btn {
  color: #ffffff;
  border: none;
}

@media screen and (max-width: 768px) {
  .h-form-actions-container {
    flex-direction: column;
  }

  .h-cancel-btn,
  .h-save-btn {
    width: 100%;
    padding: 10px;
  }
}

/* Page Content */
.h-page-content-container {
  padding: 24px;
  background-color: #ffffff;
  flex: 1;
}

@media screen and (max-width: 1024px) {
  .h-page-content-container {
    padding: 16px;
  }
}

/* Status Indicators */
.status-indicator {
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 14px;
}

.status-indicator.active {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.status-indicator.inactive {
  background-color: #fafafa;
  color: #757575;
}

.status-indicator.draft {
  background-color: #fff3e0;
  color: #ef6c00;
}