.time-recording-container {
    font-family: Arial, sans-serif;
    padding: 20px;
}
.time-recording-table {
    width: 100%;
    border-collapse: collapse;
}
.time-recording-table th,
.time-recording-table td {
        border: 1px solid #c84347;
        padding: 8px;
        text-align: left;
    }
.time-recording-table th {
        background-color: #f4f4f4;
        font-weight: bold;
    }
.time-recording-table tr:nth-child(even) {
        background-color: #f9f9f9;
    }
.time-recording-table tr:hover {
        background-color: #f1f1f1;
    }
.status-column {
    width: 50px;
}
.punch-in-column,
.punch-out-column,
.hours-column {
    width: 80px;
}
.punch-in-pos-column,
.punch-out-pos-column {
    width: 150px;
}
.user-name-column {
    width: 120px;
}
.punch-in-supervisor-column,
.punch-out-supervisor-column {
    width: 140px;
}
.monthly-previewed-out-column {
    width: 130px;
}
.search-bar-timerecord{
    height:30px;
    width:30%;
    margin-bottom:20px

}
.search-bar-timerecord::placeholder {
    color:black
}





.filter-containerss {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    margin-bottom: 20px;
}

.filter-item {
    border: 1px solid silver;
    background-color: transparent;
    padding: 10px;
    display: flex;
    flex-direction: column;
    width:100%;
    background-color:white
}

.filter-select, .filter-input {
    padding: 5px;
    border: 1px solid silver;
    background-color: transparent;
}

    .filter-select:focus, .filter-input:focus {
        outline: none;
        border-color: #6c5ce7;
    }







.orders-container-report {
    padding: 20px;
    background-color: #efefef;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    border: 1px solid silver
}