


html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
}

/* Main Container */
.cash-in-page {
height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9f2fa;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f3f4f6;
  color: #333;
  background-image: url('https://www.thedigitaltransformationpeople.com/wp-content/uploads/2018/03/grid-3227459_1920.jpg');
  background-size: cover;
  background-position: center;
  padding: 10px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.cash-in-page-2 {
  width: 95%;
  max-width: 1200px;
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 15px;
  gap: 15px;
  background: #ffffff;
  background-color: #f2f2f2;
  color: #333;
  border-radius: 10px;
  border: 1px solid #c84347;
  flex-wrap: wrap;
  box-sizing: border-box;
  overflow: hidden;
}

/* Form Container */
.form-container-cashing {
  padding: 15px;
  width: 100%;
  max-width: 500px;
  background-color: #f2f2f2;
  flex: 1 1 300px;
  box-sizing: border-box;
}

.form-groups {
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
}

.form-groups label {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 8px;
  display: block;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
}

/* Input & Select */
.inputs-amount,
select,
textarea {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 0.8px solid #c84347;
  transition: all 0.3s ease;
  margin-top: 5px;
  background-color: transparent;
  color: #474747;
  box-sizing: border-box;
}

.amount-container {
  display: flex;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
}

/* Currency Select */
.currency-select {
  width: 120px;
  min-width: 120px;
  font-size: 14px;
  background-color: transparent;
  flex-shrink: 0;
}

/* Textarea */
textarea {
  height: 80px;
  resize: none;
  width: 100%;
}

/* Keypad Container */
.keypad-container {
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
  flex: 1 1 300px;
  box-sizing: border-box;
}

.keypad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
  background-color: #f2f2f2;
  padding: 15px;
  border-radius: 8px;
  box-sizing: border-box;
}

.keypad-button {
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #c84347;
  border-radius: 8px;
  background-color: #f2f2f2;
  color: #333;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

/* Button States */
.keypad-button:hover {
  background-color: #f4f4f4;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.keypad-button:active {
  background-color: #e1e1e1;
  box-shadow: none;
}

/* Specific Button Styles */
.keypad-button.backspace {
  background-color: #f56262;
  color: white;
  font-weight: bold;
}

.keypad-button.confirm {
  background-color: #4caf50;
  color: white;
  font-weight: bold;
}

.keypad-button.confirm:hover {
  background-color: #45a049;
}

/* Responsive Breakpoints */
@media (max-width: 1024px) {
  .cash-in-page-2 {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .form-container-cashing,
  .keypad-container {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .cash-in-page {
    padding: 10px;
  }

  .cash-in-page-2 {
    width: 100%;
    padding: 10px;
    gap: 10px;
  }

  .keypad {
    padding: 10px;
  }

  .amount-container {
    flex-direction: column;
  }

  .currency-select {
    width: 100%;
    max-width: none;
  }
}

@media (max-width: 480px) {
  .cash-in-page {
    padding: 5px;
  }

  .cash-in-page-2 {
    border-radius: 5px;
  }

  .keypad-button {
    padding: 10px;
    font-size: 14px;
  }

  .form-container-cashing,
  .keypad-container {
    padding: 10px;
  }

  .inputs-amount,
  select,
  textarea {
    font-size: 14px;
    padding: 6px;
  }
}

@media screen and (max-width: 360px) {
  .cash-in-page {
    padding: 5px;
  }
  
  .keypad-button {
    min-width: 0;
    width: 100%;
  }
}

@media screen and (max-height: 700px) {
  .cash-in-page-2 {
    min-height: auto;
  }
}

/* iOS Safari Fixes */
@supports (-webkit-touch-callout: none) {
  .cash-in-page {
    min-height: -webkit-fill-available;
  }
}

@supports (-webkit-touch-callout: none) and (min-height: 100vh) {
  .cash-in-page {
    height: -webkit-fill-available;
  }
}