/* Root Container */
.NRP-container {
  font-family: Arial, sans-serif;
  color: #000;
  background-color: #fff;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

/* ================= Navbar ================= */
.NRP-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #000;
  color: #fff;
  padding: 12px 20px;
  border-radius: 6px;
}

.NRP-navbar h1 {
  margin: 0;
  font-size: 1.6rem;
  font-weight: 600;
}

.NRP-search-bar {
  padding: 8px 12px;
  border: 1px solid #fff;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  max-width: 280px;
  background-color: transparent;
  color: #fff;
}

.NRP-search-bar::placeholder {
  color: #ddd;
}

/* Responsive Navbar */
@media (max-width: 768px) {
  .NRP-navbar {
    flex-direction: column;
    gap: 12px;
    text-align: center;
  }

  .NRP-search-bar {
    width: 90%;
    max-width: none;
  }
}

/* ================= Table ================= */
.NRP-table-container {
  margin-top: 20px;
  overflow-x: auto;
}

.NRP-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #000;
  min-width: 600px;
}

.NRP-table th,
.NRP-table td {
  border: 1px solid #000;
  padding: 10px 14px;
  text-align: left;
  font-size: 0.95rem;
}

.NRP-table th {
  background-color: #000;
  color: #fff;
  font-weight: 600;
}

.NRP-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.NRP-table tr:hover {
  background-color: #eaeaea;
  cursor: pointer;
}

.no-data {
  text-align: center;
  color: #888;
  font-style: italic;
  padding: 20px;
}

/* Responsive Table */
@media (max-width: 768px) {
  .NRP-table-container {
    overflow-x: auto;
  }

  .NRP-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

/* ================= Modal ================= */
.NRP-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: opacity 0.3s ease-in-out;
}

.NRP-modal {
  width: 90%;
  max-width: 500px;
  height: auto;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

/* Close Icon */
.NRP-close-icon {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 22px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.NRP-close-icon:hover {
  color: red;
}

/* Modal Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Mobile Modal Adjustments */
@media (max-width: 480px) {
  .NRP-modal {
    width: 95%;
    max-width: 380px;
    padding: 16px;
  }

  .NRP-close-icon {
    font-size: 20px;
  }
}
