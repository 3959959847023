
html, body {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer, Edge */
}
.sidebar-toggle-btn {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.sidebar-toggle-btn:hover {
  opacity: 0.9;
}

/* Ensure smooth transitions */
.sidebar {
  transition: width 0.3s ease;
}

.main-content {
  transition: margin-left 0.3s ease, width 0.3s ease;
}
.dashboard-wrapper {
  display: flex;
  overflow: hidden;
  font-family: "Arial", sans-serif;
  -ms-overflow-style: none; /* For Internet Explorer, Edge */
  scrollbar-width: none; /* For Firefox */
}
.dashboard-wrapper::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}


.sidebar {
  width: 250px;
  color: white;
  padding: 20px;
  /* height: 100vh; */
  box-shadow: 4px 0 15px rgba(0, 0, 0, 0.3);
  transition: width 0.3s ease, background 0.3s ease; /* Smooth transitions */
  position: relative; /* For the animation effect */
  padding-top: 30px;
  padding-bottom: 100px;
  overflow-y: auto; /* Enables vertical scrolling */
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
}

.sidebar h3 {
  margin-bottom: 30px;
  font-size: 28px; /* Larger title */
  text-align: center;
  letter-spacing: 1px;
  color: #ffffff; /* Bright gold for title */
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  padding: 8px 12px;
  cursor: pointer;
  color: #ffffff; /* Light yellow */
  /* border-radius: 8px; */
  position: relative;
  transition: background 0.3s ease, color 0.3s ease;
  overflow: hidden; /* For the animated effect */
}

.sidebar ul li::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #000000; /* Gold underline */
  transition: width 0.3s ease, left 0.3s ease; /* Animation */
}

.sidebar ul li:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Slightly transparent white */
  color: #000000; /* White text on hover */
}

.sidebar ul li.active {
  background-color: #ffffff; /* Bright gold for active */
  color: #000000; /* Dark text for active */
}

.sidebar ul li.active::after {
  width: 100%; /* Underline for active */
  left: 0; /* Position it to the left */
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  transition: background-color 0.3s;
  min-height: 100vh;
  overflow: scroll;
  scrollbar-width: none;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100vw;
}

.hamburger {
  padding: 20px;
  display: none;
}
.sidebar-2 {
  display: none;
}
/* Optional: Add a responsive design */
@media (max-width: 768px) {
  .hamburger {
    padding: 20px;
    display: block;
  }
  .sidebar {
    display: none;
  }
  .sidebar-2 {
    display: block;
    width: 250px;

    color: white;
    padding: 20px;
    height: 100vh;
    box-shadow: 4px 0 15px rgba(0, 0, 0, 0.3);
    transition: width 0.3s ease, background 0.3s ease; /* Smooth transitions */
    position: relative; /* For the animation effect */
    padding-top: 30px;
    padding-bottom: 30px;
    z-index: 1000;
    position: absolute;
  }

  .sidebar-2 h3 {
    margin-bottom: 30px;
    font-size: 28px; /* Larger title */
    text-align: center;
    letter-spacing: 1px;
    color: #ffffff; /* Bright gold for title */
  }

  .sidebar-2 ul {
    list-style-type: none;
    padding: 0;
  }

  .sidebar-2 ul li {
    padding: 15px 20px;
    cursor: pointer;
    color: #ffffff; /* Light yellow */
    /* border-radius: 8px; */
    position: relative;
    transition: background 0.3s ease, color 0.3s ease;
    overflow: hidden; /* For the animated effect */
  }

  .sidebar-2 ul li::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 0;
    height: 3px;
    background: #000000; /* Gold underline */
    transition: width 0.3s ease, left 0.3s ease; /* Animation */
  }

  .sidebar-2 ul li:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Slightly transparent white */
    color: #000000; /* White text on hover */
  }

  .sidebar-2 ul li.active {
    background-color: #ffffff; /* Bright gold for active */
    color: #000000; /* Dark text for active */
  }

  .sidebar-2 ul li.active::after {
    width: 100%; /* Underline for active */
    left: 0; /* Position it to the left */
  }
  .cross {
    text-align: end;
    font-size: 20px;
  }
}

/* src/WelcomeScreen.css */
.welcome-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #ffcdce;
  /* background: linear-gradient(135deg, rgba(200, 67, 71, 1) 0%, rgba(247, 92, 91, 1) 100%); */
  color: white;
  font-family: "Arial", sans-serif;
}

.welcome-content {
  text-align: center;
  padding: 30px;
  background-color: #c84347; /* Dark background for content area */
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  max-width: 450px;
}

.welcome-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: bold;
}

.welcome-description {
  font-size: 1.1rem;
  margin-bottom: 30px;
}

.cta-button {
  padding: 15px 30px;
  background-color: #f95d56;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #e4463f;
}

.cta-button:active {
  background-color: #c93732;
}
.for-adding {
  width: 100%;
  display: flex;
  gap: 20px;
}
.for-adding div {
  width: 50%;
}
@media (max-width: 600px) {
  .for-adding {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .for-adding div {
    width: 100%;
  }
  .welcome-content {
    padding: 20px;
    max-width: 100%;
  }

  .welcome-title {
    font-size: 2rem;
  }

  .welcome-description {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 12px 25px;
  }
}
.password-prompt {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-prompt-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
}

.password-prompt input {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
}

.password-prompt button {
  padding: 8px 15px;
  background-color: #c84347;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.password-prompt button:hover {
  background-color: #a03a33;
}

/* Popup Overlay */
.update-form {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Dark backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensures the modal is on top */
}

.update-form .form-container {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  width: 400px;
  max-width: 100%;
  position: relative;
}

.update-form h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

.update-form label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
}

.update-form input[type="text"],
.update-form input[type="number"],
.update-form input[type="file"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.update-form button[type="submit"],
.update-form button[type="button"] {
  background-color: #c84347;
  color: white;
  border: none;
  padding: 12px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}

.update-form
  button[type="submit"]:hover
  .update-form
  button[type="button"]:hover {
  background-color: #e74c3c;
}

.update-form .cancel-btn {
  background-color: #ddd;
}

.update-form .cancel-btn:hover {
  background-color: #ccc;
}

.update-form .form-container .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}
.main-option-1 {
  display: flex;
  justify-content: space-between;
}
.main-option-1 li{
  margin-top: 30px;
}
.main-option-1 div{
  margin-top: 30px;
}
.main-option-1-1 {
  color: white;

  font-size: 20px;
  display: flex;
  justify-content: space-between;
  user-select: none;
  font-weight: 500;
  /* text-decoration: underline; */
}
.main-option-1-1-1 {
  color: white;

  font-size: 20px;
  display: flex;
  justify-content: space-between;
  user-select: none;
  font-weight: 500;
}
.main-option-1-1 {
  cursor: pointer;
}
