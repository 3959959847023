.h-article-details {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  color: #333;
  font-family: Arial, sans-serif;
  height: 80vh; /* Set the height of the form container */
  overflow-y: auto; /* Enable vertical scrolling */
  box-sizing: border-box;
}

.h-article-details h2 {
  text-align: center;
  color: #c84347;
}

.h-article-details section {
  margin-bottom: 30px;
}

.h-article-details section h3 {
  color: #c84347;
  border-bottom: 2px solid #c84347;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.h-article-details label {
  display: block;
  margin: 10px 0 5px;
  font-weight: bold;
}

.h-article-details input,
select,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.h-article-details input[type="checkbox"] {
  width: auto;
  margin-right: 10px;
}

.h-article-details textarea {
  height: 100px;
  resize: vertical;
}

.h-article-details input[type="text"]:disabled,
select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.h-article-details select {
  background-color: #fff;
}

.h-article-details button {
  background-color: #c84347;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.h-article-details button:hover {
  background-color: #a73939;
}

.h-article-details input[type="checkbox"]:checked + label {
  font-weight: normal;
}

/* Responsive design */
@media (max-width: 768px) {
  .article-details {
    width: 100%;
    padding: 10px;
  }

  input,
  select,
  textarea {
    width: 100%;
  }
}
