.theme-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
}
.theme-card {
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 350px;
    text-align: center;
}
.theme-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
}
.theme-input-group {
    margin-bottom: 15px;
}
.theme-label {
    display: block;
    font-size: 1rem;
    font-weight: 500;
    color: #555;
    margin-bottom: 5px;
}
.theme-input {
    width: 80%;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #ccc;
    height: 80px;
}
.theme-input-2 {
    width: 80%;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #ccc;
}
.theme-logo-preview {
    margin-top: 15px;
}
.theme-logo {
    max-width: 100px;
    height: auto;
    border-radius: 8px;
    border: 2px solid #ddd;
    padding: 5px;
}
