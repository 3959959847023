.receipt-container {
    display: flex;
    width: 100%;
    height: 100vh;
    font-family: Arial, sans-serif;
    flex-direction: column;
}
.receipt-container-mini{
    display: flex;
    width: 50%;
    height:50vh;
    font-family: Arial, sans-serif;
    flex-direction: column;
}
@media (min-width: 768px) {
    .receipt-container {
        flex-direction: row;
    }
}

.receipt-sidebar {
    width: 100%;
    background-color: #f4f4f4;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media (min-width: 768px) {
    .receipt-sidebar {
        width: 250px;
    }
}

.receipt-tab {
    background: none;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    text-align: left;
    transition: 0.3s;
}

.receipt-active-tab {
    font-weight: bold;
    color: white;
    background-color: #c84347;
    padding: 10px;
    border: none;
}

.receipt-content {
    flex-grow: 1;
    padding: 20px;
}

.receipt-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
}

.receipt-grid-item {
    background-color: #e0e0e0;
    border: none;
    padding: 15px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
}

.receipt-grid-item:hover {
    background-color: #d0d0d0;
}

.uniques-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 10px;
}

.uniques-popup-content {
    height: 83vh;
    width: 90vw;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
}

@media (min-width: 1024px) {
    .uniques-popup-content {
        width: 80vw;
    }
}

.main-tax-rates {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.tax-rates {
    padding: 10px;
    font-size: 30px;
}

.tax-rates:hover {
    background-color: #ffd1d1;
    width: 100%;
}

.cancel-receipt {
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.cancel-receipt-text {
    font-size: 24px;
    text-align: center;
}

.cancel-receipt-button {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid red;
    color: red;
    background: none;
}

@media (max-width: 480px) {
    .tax-rates {
        font-size: 20px;
    }
    .cancel-receipt-text {
        font-size: 20px;
    }
}


/* Unique container styling */
.hungrie-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    padding: 20px;
    background-color: #f4f7fc;
    flex-direction: column;
    height: 75vh;
    overflow-y: scroll;
}

/* Unique box styling */
.hungrie-box {
    width: 320px;
    background: #ffffff;
    border: 2px solid #d1d9e6;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* Title Styling */
.hungrie-box-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 12px;
}

/* List Styling */
.hungrie-box-list {
    list-style: none;
    padding: 0;
    max-height: 400px;
    overflow-y: auto;
}

/* Individual Item Styling */
.hungrie-box-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #eef3fc;
    padding: 12px;
    margin-bottom: 10px;
    border-radius: 8px;
    transition: all 0.3s;
}

.hungrie-box-item:hover {
    background: #dde7f8;
}

/* Image Styling */
.hungrie-item-image {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 6px;
}

/* Item Title */
.hungrie-item-title {
    flex-grow: 1;
    padding-left: 10px;
    font-weight: 500;
    color: #34495e;
}

/* Move Button Styling */
.hungrie-move-btn {
    background: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s;
    font-weight: bold;
}

.hungrie-move-btn:hover {
    background: #0056b3;
}
.hungrie-move-btn,
.hungrie-print-btn {
    background: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s;
    font-weight: bold;
}

.hungrie-print-btn {
    width: 100%;
}

.hungrie-move-btn:hover,
.hungrie-print-btn:hover {
    background: #0056b3;
}


.password-change-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.input-password-f{
    height: 30px;
    width: 300px;
    margin: 10px;
}
.btn-password-f{
    height: 30px;
    width: 300px;
    margin: 10px;
}