/* Main Layout */
.h-articles-container {
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-rows: auto auto 1fr;
  min-height: 100vh;
  background-color: #fff;
}

/* Sidebar Styles */
.h-articles-container .sidebar {
  grid-row: 1 / -1;
  background-color: #fff;
  border-right: 1px solid #e1e4e8;
  padding: 1.5rem;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.h-articles-container .sidebar .article-list {
  flex-grow: 1;
  overflow-y: auto;
  margin: 0 0 1rem 0;
}

.h-articles-container .sidebar ul {
  padding: 0;
  list-style: none;
}

.h-articles-container .sidebar li {
  padding: 0.75rem 1rem;
  margin: 0.25rem 0;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.h-articles-container .sidebar li:hover {
  background-color: #f8f0f0;
  color: #c84347;
}

.h-articles-container .sidebar .add-article-btn {
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: #c84347;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: auto;
}

.h-articles-container .sidebar .add-article-btn:hover {
  background-color: #b13c3f;
}

/* Navbar Styles */
.h-articles-container .navbar {
  grid-column: 2;
  background-color: #fff;
  padding: 1rem 2rem;
  border-bottom: 1px solid #e1e4e8;
}

.h-articles-container .navbar h2 {
  margin: 0;
  color: #24292e;
  font-size: 1.25rem;
}

.h-articles-container .navbar p {
  margin: 0.5rem 0 0;
  color: #586069;
}

/* Sub-navbar Styles */
.h-articles-container .sub-navbar {
  grid-column: 2;
  background-color: #fff;
  padding: 1rem 2rem;
  border-bottom: 1px solid #e1e4e8;
  display: flex;
  gap: 1rem;
  cursor: pointer;
}

.sub-navbar button {
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  color: #586069;
  cursor: pointer;
  transition: all 0.2s ease;
}

.sub-navbar button:hover {
  background-color: #f8f0f0;
  color: #c84347;
}

/* Form Overlay Styles */
.form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.article-form {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.article-form h2 {
  margin: 0 0 1.5rem;
  color: #24292e;
}

.article-form h3 {
  margin: 1.5rem 0 1rem;
  color: #c84347;
  border-bottom: 1px solid #e1e4e8;
  padding-bottom: 0.5rem;
}

.article-form input[type="text"],
.article-form select,
.article-form textarea {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  font-size: 0.875rem;
  transition: border-color 0.2s ease;
  background-color: #fff;
}

.article-form textarea {
  min-height: 100px;
  resize: vertical;
}

.article-form input:focus,
.article-form select:focus,
.article-form textarea:focus {
  outline: none;
  border-color: #c84347;
  box-shadow: 0 0 0 3px rgba(200, 67, 71, 0.1);
}

.article-form .checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  color: #586069;
}

.article-form input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  accent-color: #c84347;
}

.article-form .form-actions {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
}

.article-form button[type="submit"] {
  background-color: #c84347;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  margin-right: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.article-form button[type="submit"]:hover {
  background-color: #b13c3f;
}

.article-form button[type="button"] {
  background-color: #fff;
  color: #c84347;
  padding: 0.75rem 1.5rem;
  border: 1px solid #c84347;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.article-form button[type="button"]:hover {
  background-color: #f8f0f0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .articles-container {
      grid-template-columns: 1fr;
  }

  .sidebar {
      grid-row: auto;
      border-right: none;
      border-bottom: 1px solid #e1e4e8;
  }

  .navbar,
  .sub-navbar {
      grid-column: 1;
  }

  .article-form {
      width: 95%;
      padding: 1.5rem;
  }
}