.h-pos-user-page {
    padding: 20px;
  }
  
  .h-pos-user-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .h-pos-user-table th,
  .h-pos-user-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .h-pos-user-table th {
    background-color: #f4f4f4;
  }
  
  .h-pos-user-table td input[type="checkbox"] {
    cursor: pointer;
  }
  
  .h-pos-user-table td select {
    padding: 5px;
  }
  