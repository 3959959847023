.price-page {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .price-page h2 {
    text-align: center;
    color: #c84347;
    margin-bottom: 20px;
  }
  
  .price-page table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .price-page th,
  td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .price-page th {
    background-color: #f4f4f4;
    color: #c84347;
  }
  
  .price-page button.delete-icon {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 18px;
  }
  
  .price-page button.delete-icon:hover {
    color: darkred;
  }
  
  tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    table {
      width: 100%;
      font-size: 14px;
    }
  
    th,
    td {
      padding: 8px;
    }
  
    button.delete-icon {
      font-size: 16px;
    }
  }
  