/* Global Styles */
html, body {
  overflow-x: hidden;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

/* Container styling */
.form-container, .form-container2, .employee-form, .employee-details, .bestEverContainer {
  width: 100%;
  max-width: 90vw;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

/* Form Styling */
.employee-form {
  max-width: 600px;
  width: 100%;
  height: 70vh;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  overflow-x: hidden; /* Prevents horizontal scrolling */
  display: flex;
  flex-direction: column;
}

/* Webkit Scrollbar Styling */
.employee-form::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.employee-form::-webkit-scrollbar-thumb {
  background-color: #c84347;
  border-radius: 5px;
}

.employee-form::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

/* Title Styling */
.form-title, .title, .salesReportTitle {
  text-align: center;
  /* color: #c42027; */
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Input Fields Styling */
.employee-form input, .select-data, .filterInput, .filterSelect {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
}

/* Submit Button Styling */
.submit-button, .downloadButton, .close-button {
  /* background-color: #c42027; */
  color: #fff;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

/* .submit-button:hover, .downloadButton:hover, .close-button:hover {
  background-color: #a3181f;
} */

/* Employee Container */
.employee-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Employee List */
.employee-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

/* Employee Card */
.employee-card {
  background-color: #eeeeee;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  width: 200px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.employee-card:hover {
  transform: scale(1.05);
}

/* Employee Details Modal */
.employee-details {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

/* Employee Details Modal Text */
.employee-details h2 {
  /* color: #c42027; */
  margin-bottom: 15px;
}

.employee-details p {
  margin: 5px 0;
}

/* Best Sales Report Section */
.bestEverContainer {
  padding: 20px;
  background-color: #fff;
}

/* Filters Section */
.filtersSection {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: row;
}

/* Table Styling */
.ordersTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  /* border: 1px solid #c84347; */
  overflow-x: auto;
  display: block;
}

.ordersTable th, .ordersTable td {
  padding: 10px;
  text-align: left;
  /* border: 1px solid #c84347; */
  color: black;
  white-space: nowrap;
}

/* Total Sales */
.totalSales {
  font-size: 22px;
  color: #c84347;
  margin-top: 20px;
  text-align: center;
}

/* Media Queries for Responsive Design */
@media screen and (max-width: 1024px) {
  .form-container, .form-container2, .employee-form {
      width: 95vw;
      padding: 15px;
  }

  .employee-list {
      flex-direction: column;
      align-items: center;
  }

  .employee-card {
      width: 90%;
  }

  .filtersSection {
      flex-direction: column;
      align-items: stretch;
  }

  .filterInput, .filterSelect {
      width: 100%;
  }

  .ordersTable {
      display: block;
      overflow-x: auto;
  }
}

@media screen and (max-width: 768px) {
  .form-container, .form-container2, .employee-form {
      width: 98vw;
      padding: 10px;
  }

  .employee-card {
      width: 100%;
  }

  .employee-details {
      width: 95%;
      max-width: 400px;
  }

  .filtersSection {
      flex-direction: column;
      gap: 5px;
  }

  .filterInput, .filterSelect {
      width: 100%;
  }

  .ordersTable {
      overflow-x: auto;
  }
}

@media screen and (max-width: 480px) {
  .form-title, .title, .salesReportTitle {
      font-size: 20px;
  }

  .submit-button, .downloadButton, .close-button {
      padding: 10px;
      font-size: 14px;
  }

  .employee-details {
      width: 95%;
      padding: 15px;
  }

  .employee-card {
      width: 100%;
  }

  .ordersTable th, .ordersTable td {
      padding: 8px;
  }
}
.filterInput, .filterSelect {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

