/* Base Styles */
.h-pos-container {
  display: flex;
  height: 100vh;
  border: 2px solid;
  overflow: hidden;
  border: 1px solid silver;

}

.h-pos-sidebar {
  width: 300px;
  border-right: 2px solid;
  display: flex;
  flex-direction: column;
  padding: 10px;
  transition: all 0.3s ease;
  border: 1px solid silver;

}
.h-pos-main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Responsive Breakpoints */
@media screen and (max-width: 1024px) {
  .h-pos-container {
    flex-direction: column;
    height: auto;
  }

  .h-pos-sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 2px solid;
  }

  .h-pos-main-content {
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .h-pos-sidebar {
    max-height: 300px;
    overflow-y: auto;
  }

  .h-pos-sub-navbar {
    flex-wrap: wrap;
  }

  .sub-nav-item {
    flex-grow: 1;
    text-align: center;
  }

  .h-pos-new-form {
    padding: 10px;
  }

  .h-pos-new-form label {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .h-pos-container {
    min-height: 100vh;
  }

  .h-pos-sidebar {
    max-height: 250px;
  }

  .h-pos-dropdown-menu {
    position: static;
    width: 100%;
  }

  .h-pos-sub-navbar {
    flex-direction: column;
  }

  .sub-nav-item {
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
  }
}

/* Existing Styles (Keep previous CSS from the last response) */
.h-pos-sidebar-header {
  border-bottom: 2px solid;
  padding: 10px 0;
  text-align: center;
}

.h-pos-roles-list {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px 0;
}

.h-pos-tree-node {
  margin-bottom: 5px;
}

.tree-node-content {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tree-node-content:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.tree-node-content.selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.h-pos-expand-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.h-pos-node-info {
  flex-grow: 1;
}

.h-pos-role-name {
  font-weight: 500;
}

.h-pos-delete-button {
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.h-pos-delete-button:hover {
  opacity: 1;
}

.h-pos-tree-node-children {
  padding-left: 20px;
}

.h-pos-add-role-section {
  padding: 10px;
  border-top: 1px solid #e0e0e0;
}

.h-pos-dropdown {
  position: relative;
}

.h-pos-add-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.h-pos-dropdown-menu {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #e0e0e0;
  background-color: white;
  z-index: 10;
}

.h-pos-dropdown-item {
  width: 100%;
  padding: 10px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
}

.h-pos-navbar {
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.h-pos-sub-navbar {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
}

.sub-nav-item {
  padding: 10px 15px;
  border: none;
  background: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sub-nav-item.active {
  font-weight: bold;
  border-bottom: 2px solid;
}

.h-pos-tab-content {
  padding: 20px;
  overflow-y: auto;
}

.h-pos-new-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
}

.h-pos-new-form label {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.h-pos-new-form input,
.h-pos-new-form select {
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.h-pos-save-button {
  padding: 10px;
  border: none;
  cursor: pointer;
}

.h-pos-placeholder-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}