/* QuickSelection Page Styles */
.h-pos-quick-selection-page {
  padding: 20px;
}

.h-pos-quick-selection-page h2 {
  margin-bottom: 20px;
  font-size: 20px; /* Reduced font size for the heading */
  color: #333;
}

.h-pos-quick-selection-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.h-pos-quick-selection-table th,
.h-pos-quick-selection-table td {
  padding: 8px; /* Reduced padding for a more compact look */
  border: 1px solid #ddd;
  font-size: 14px; /* Smaller font size */
}

.h-pos-quick-selection-table th {
  background-color: #f4f4f4;
}

.h-pos-quick-selection-table td select {
  padding: 6px; /* Smaller padding for select elements */
  font-size: 14px; /* Adjusted font size for select elements */
}
