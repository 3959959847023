.orders-container {
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    border:1px solid gray
  }
  
  .title {
    font-size: 24px;
    color: #d32a0d;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .loading-text {
    font-size: 18px;
    color: #666;
    text-align: center;
  }
  
  .orders-list {
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns:repeat(3, 1fr);
    gap: 20px;
  }
  
  .order-item {
    background-color: #fff;
    margin: 10px 0;
    padding: 15px;
    border: 1px solid #d32a0d;
    border-radius: 8px;
    transition: box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }
  
  .order-item:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .order-details {
    font-size: 16px;
    margin: 0 0 10px;
  }
  
  .order-number {
    font-weight: bold;
    color: #d32a0d; /* Highlight the order number */
  }
  
  .total {
    color: #d32a0d;
    font-weight: bold;
  }
  
  .status {
    font-style: italic;
  }
  
  .line-items {

    list-style: none;
    padding: 0;

  }
  .for-img{
    display: flex;
    width: 100%;
    
    justify-content: space-between;
    align-items: center;
  }
  .line-item {
    list-style: none;
    padding: 5px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .item-price {
    font-weight: bold;
  }
  
  .buttons {
    margin-top: 10px;
  }
  
  .status-button {
    padding: 8px 12px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .status-button.on-hold {
    background-color: #f57c00; /* Orange */
  }
  
  .status-button.completed {
    background-color: #388e3c; /* Green */
  }
  
  .status-button:hover {
    opacity: 0.9;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal h2 {
    margin-top: 0;
}
.orders-container {
  position: relative;
  padding: 20px;
}

.orders-title {
  font-size: 2em;
  text-align: center;
  margin-bottom: 20px;
}

.search-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

}

.search-input {
  padding: 10px;
  width: 100%;
  border: 1px solid #c84347;
  border-radius: 5px;
  margin-right: 10px;
}

.toggle-cart-button {
  padding: 10px 15px;
  background-color: #d32a0d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-cart-button:hover {
  background-color: #b0200a; /* Darker shade on hover */
}

.item-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 70vh;
  overflow-y: scroll;
  scrollbar-width: none;
}
/* Webkit Scrollbar Styling */
.item-list::-webkit-scrollbar {
  height: 3px; /* Adjust scrollbar height */
}

.item-list::-webkit-scrollbar-thumb {
  background-color: #c84347; /* Thumb color */
  border-radius: 5px; /* Rounded corners for the thumb */
}

.item-list::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Track color */
}
.item-card {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffb3b3;
  /* padding: 10px; */
  margin: 10px;
  border-radius: 5px;
  background-color: white;
  box-shadow: -2px 0 10px rgba(163, 163, 163, 0.3);
  height: auto;
  padding: 20px;
}

.item-image {
  max-width: 100%;
  height: 100px;
  display: block;
  margin-top: 10px;
  border-radius: 20px;
}

.item-title {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  text-align: center;
  padding: 10px;
}

.item-category {
  margin: 5px 0;
  font-style: italic;
}

.item-prices {
  margin: 5px 0;
  /* font-weight: bold; */
}

.add-to-cart-button {
  /* padding: 5px 10px; */
  width: 100%;
  height: 30px;
  background-color: #ebebeb;
  color: rgb(0, 0, 0);
  border: 1px solid #a6a6a6;
  /* border-radius: 5px; */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-to-cart-button:hover {
  color: rgb(0, 0, 0);
  background-color: #b0200a; /* Darker shade on hover */
}
/* Sidebar Styles */

.orders-container2{
  display: flex;
  /* justify-content: space-between; */
  padding: 30px;
  /* width:95%; */
  }
  .sec-1{
    max-width: 70%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
  }
  .sec-2{
    width: 20%;
  }
  .open{
    width: 300px;
  height: 100%;
  background-color: #ffffff; /* Main color */
  color: white;
  border: 1px solid #C84347;
  /* box-shadow: -2px 0 10px rgba(0, 0, 0, 0.3); */
  /* transform: translateX(100%); */
  transition: transform 0.3s ease;
  padding: 20px;
  z-index: 1000;
  overflow-y: auto; 
  padding-bottom: 100px;/* Enable scrolling if content overflows */
  border-radius: 10px;
  }

.cart-sidebar.open {
  transform: translateX(0);
}

.cart-title {
  font-size: 1.8em;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  padding-bottom: 10px;
  color:#c84347
}

.close-sidebar-button {
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  position: absolute;
  top: 15px;
  right: 15px;
  transition: transform 0.2s ease;
}

.close-sidebar-button:hover {
  transform: scale(1.2); /* Slightly enlarge button on hover */
}

.cart-items-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color:black
}

.cart-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(239, 218, 218, 0.1); /* Semi-transparent white */
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cart-item:hover {
  background: rgba(255, 255, 255, 0.2); /* Lighten on hover */
}

.cart-item span {
  font-weight: bold; /* Highlight item names */
}

.remove-item-button {
  color: #000000; /* Main color for remove button */
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.remove-item-button:hover {
  color: #ff6f61; /* Lighter shade on hover */
}

.total-price {
  font-weight: bold;
  margin-top: 20px;
  text-align: center; /* Center total price */
  font-size: 1.2em;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  padding: 10px;
  border-radius: 5px;
  color:'#c84347s'
}

/* Extra Sauces and Toppings Section */
h3 {
  margin: 20px 0 10px; /* Space between title and items */
  text-transform: uppercase; /* Uppercase for titles */
  font-size: 1.2em;
  color: #ffffff; /* Light color for headings */
  border-bottom: 2px solid rgba(0, 0, 0, 0.5); /* Underline effect */
  padding-bottom: 5px; /* Spacing under title */
}

.sauce-item,
.topping-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  background: rgba(251, 251, 251, 0.1); /* Light background for items */
  border-radius: 5px;
  transition: background-color 0.3s ease;
  color:black
}

.sauce-item:hover,
.topping-item:hover {
  background: rgba(255, 255, 255, 0.2); /* Lighten on hover */
}

.quantity-button {
  background: #ffffff; /* Bright shade for buttons */
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid black
}

.quantity-button:hover {
  background: #ff4a3a; /* Darker red on hover */
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other elements */
}

.popup-content {
  background-color: #C84347; /* Main color */
  color: white; /* Text color */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Spacing inside the popup */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Subtle shadow */
  width: 90%; /* Responsive width */
  max-width: 400px; /* Max width */
  /* text-align: center; Center text */
  position: relative; /* For positioning the close button */
}
.popup-content2 {
  background-color: #C84347; /* Main color */
  color: white; /* Text color */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Spacing inside the popup */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Subtle shadow */
  width: 90%; /* Responsive width */
  max-width: 400px; /* Max width */
  /* text-align: center; Center text */
  position: relative; /* For positioning the close button */
  
}
.size-option {
  margin: 10px 0; /* Spacing between options */
}

.add-to-cart-button {
  background-color: white; /* Button color */
  color: #C84347; /* Text color */
  border: none;
  /* border-radius: 5px; */
  padding: 10px 15px; /* Padding for the button */
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease; /* Smooth transition */
}

.add-to-cart-button:hover {
  background-color: #e0e0e0; /* Light grey on hover */
}

.close-button {
  background: transparent; /* Transparent background */
  color: white; /* Close button text color */
  border: none;
  font-size: 20px; /* Larger font */
  cursor: pointer; /* Pointer cursor */
  position: absolute; /* Position it in the top-right */
  right: 15px;
  top: 15px;
}
/*input::placeholder{
  color :white;
}*/
.blurred {
  filter: blur(5px);
}

.password-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; /* Keep the password form above blurred content */
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.password-form {
  display: flex;
  flex-direction: column;
}
.filter-checkboxes {
  display: flex;
  flex-direction: row;
  gap: 15px; /* Increased gap for better spacing */
  margin-bottom: 20px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  gap: 8px; /* Ensures proper spacing between checkbox and text */
}

.filter-checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #c84347;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

/* Checked state */
.filter-checkbox:checked {
  background-color: #c84347;
  border-color: #c84347;
}

/* Custom checkmark */
.filter-checkbox:checked::after {
  content: '✔';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: white;
  font-weight: bold;
}

/* Improve hover effect */
.filter-checkbox:hover {
  border-color: #a83235;
}

/* Checkbox text style */
.checkbox-text {
  font-size: 16px;
  color: #555;
  user-select: none;
}

@media(max-width:1000px){
  .orders-list{
    grid-template-columns:repeat(2, 1fr);

  }
}


@media(max-width:768px){
  .orders-list{
    grid-template-columns:repeat(1, 1fr);

  }
  .order-item-detail{
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
  .filter-checkboxes {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  .for-img{
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
.search-input::placeholder{
  color: gray;
}
.billing-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
}

.close-popup-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #c84347;
  color: rgb(0, 0, 0);
  border: none;
  cursor: pointer;
}
.order-item-detail{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.payment-input::placeholder{
  color:black
}/* Container for the category buttons */
.category-boxes {
  display: flex;
  overflow-x: auto; /* Allow horizontal scrolling */
  scroll-behavior: smooth; /* Smooth scrolling for a better experience */
  white-space: nowrap; /* Prevent wrapping of items */
  padding: 10px 0; /* Spacing around the container */
  gap: 10px; /* Space between items */
  justify-content: flex-start; /* Align items to the left */
  align-items: center; /* Align items vertically */
  /* scrollbar-width: none; */
}

/* Webkit Scrollbar Styling */
.category-boxes::-webkit-scrollbar {
  height: 3px; /* Adjust scrollbar height */
}

.category-boxes::-webkit-scrollbar-thumb {
  background-color: #c84347; /* Thumb color */
  border-radius: 5px; /* Rounded corners for the thumb */
}

.category-boxes::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Track color */
}
/* Webkit Scrollbar Styling */
.main-form-sty::-webkit-scrollbar {
  height: 3px; /* Adjust scrollbar height */
  width: 3px;
  padding-right:5px;
}

.main-form-sty::-webkit-scrollbar-thumb {
  background-color: #c84347; /* Thumb color */
  border-radius: 5px; /* Rounded corners for the thumb */
}

.main-form-sty::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Track color */
}
/* Category Box Styles */
.category-box {
  flex-shrink: 0; /* Prevent shrinking to fit smaller containers */
  background-color: #ffffff;
  color: #333;
  border: 1px solid #ffb3b3;
  border-radius: 12px;
  padding: 5px 15px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  min-width: 100px; /* Adjust width to fit the text properly */
  height: 50px; /* Consistent height */
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Active State for the Button */
.category-box.active {
  background-color: #c84347; /* Highlight active button */
  color: #ffffff; /* White text for contrast */
  border-color: #c84347;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Hover State for Buttons */
.category-box:hover {
  transform: translateY(-3px); /* Slight lift effect */
  background-color: #f5f5f5; /* Light gray background */
  border-color: #c84347;
}

/* Adjust Spacing for Icons */
.category-box i {
  font-size: 20px;
  margin-bottom: 5px; /* Space between icon and text */
  color: #c84347;
}

.category-box span {
  font-size: 12px;
}

/* Mobile View: Stack Boxes Vertically */
@media (max-width: 768px) {
  .category-boxes {
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    justify-content: center; /* Center align buttons */
    gap: 15px; /* Increase gap for better spacing */
  }

  .category-box {
    width: 100%; /* Full width for better stacking */
    max-width: 250px; /* Max width for buttons */
    height: auto; /* Allow height to adjust */
  }
}







.logs-container {
    max-width: 900px;
    margin: 40px auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.logs-title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 5px;
    border-bottom: 2px solid;
}

.logs-table-container {
    overflow-x: auto;
}

.logs-table {
    width: 100%;
    border-collapse: collapse;
}

    .logs-table th, .logs-table td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    .logs-table th {
        font-weight: bold;
    }

    .logs-table tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    .logs-table tr:hover {
        background-color: #f1f1f1;
    }

.no-logs {
    text-align: center;
    padding: 20px;
    font-size: 16px;
    color: #888;
}
.scroll-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

.scroll-button {
  padding: 10px 20px;
  background-color: var(--theme-color, #c84347);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.scroll-button:hover {
  opacity: 0.9;
}