/* General container styling */
.h-drawers-container {
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

/* Sidebar styling */
.h-drawers-sidebar {
  width: 280px;
  background-color: #f8f9fa;
  border-right: 1px solid #ddd;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.h-drawers-list-container {
  flex-grow: 1;
  overflow-y: auto;
}

.h-drawers-item-container {
  padding: 12px;
  margin: 5px 0;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}


.h-add-drawers-btn {
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

/* Main content area */
.h-drawers-main-content-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

/* Navbar Styling */
.h-drawers-navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: white;
  border-bottom: 1px solid #ddd;
}

.h-drawers-info-container {
  display: flex;
  gap: 20px;
}

.h-drawers-status-indicator.active {
  color: green;
}
.h-drawers-status-indicator.inactive {
  color: red;
}

/* Sub-navbar */
.h-drawers-sub-navbar-container {
  display: flex;
  border-bottom: 2px solid #ddd;
  background-color: white;
}

.h-drawers-sub-nav-tab {
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.3s;
}



/* Form styling */
.h-drawers-form-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Updated Drawer Form */
.h-drawers-form-container {
  background: white;
  padding: 25px;
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}


.h-drawers-form-container input,
.h-drawers-form-container select {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.h-drawers-form-actions-container {
  display: flex;
  justify-content: space-between;
}

.h-drawers-cancel-btn, .h-drawers-save-btn {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .h-drawers-sidebar {
    width: 220px;
  }
}

@media (max-width: 768px) {
  .h-drawers-container {
    flex-direction: column;
  }
  .h-drawers-sidebar {
    width: 100%;
    border-right: none;
  }
  .h-drawers-navbar-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .h-drawers-sub-navbar-container {
    flex-wrap: wrap;
  }
  .h-drawers-sub-nav-tab {
    padding: 8px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .h-drawers-form-container {
    max-width: 90%;
    padding: 20px;
  }

  .h-drawers-form-container input,
  .h-drawers-form-container select {
    font-size: 14px;
    padding: 8px;
  }

  .h-drawers-form-actions-container {
    flex-direction: column;
    gap: 10px;
  }

  .h-drawers-save-btn,
  .h-drawers-cancel-btn {
    width: 100%;
    padding: 12px;
  }
}
