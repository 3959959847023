/* Assigned Softwares Page Styles */
.h-pos-assigned-softwares-page {
    padding: 20px;
}

.h-pos-assigned-softwares-page h2 {
    margin-bottom: 20px;
    font-size: 20px;
    color: #333;
}

.h-pos-assigned-softwares-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}

.h-pos-assigned-softwares-table th,
.h-pos-assigned-softwares-table td {
    padding: 8px; 
    border: 1px solid #ddd;
    font-size: 14px; 
}

.h-pos-assigned-softwares-table th {
    background-color: #f4f4f4;
}

.h-pos-assigned-softwares-table td {
    text-align: center; 
}
