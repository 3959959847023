/* Receipt Component Styles */
.h-receipt-container {
  font-family: Arial, sans-serif;
  color: #c84347;
  background-color: #fff;
  padding: 20px;
}

/* Navbar */
.h-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #c84347;
  color: #fff;
  padding: 12px 20px;
  border-radius: 6px;
  flex-wrap: wrap; /* Allows wrapping for small screens */
}

.h-navbar h1 {
  margin: 0;
  font-size: 18px;
}

.h-search-bar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  max-width: 300px;
  padding-left: 10px;
}

.h-search-bar {
  width: 100%;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 1rem;
  border: 1px solid #c84347;
  outline: none;
}

/* Table */
.h-table-container {
  margin-top: 20px;
  overflow-x: auto; /* Enables horizontal scrolling */
  width: 100%;
}

.h-receipt-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
  border: 1px solid #000;
}

/* Table Headers */
.h-receipt-table th {
  background-color: #c84347;
  color: #fff;
  font-size: 14px;
  padding: 10px;
  text-align: center;
  white-space: nowrap;
}

/* Table Cells */
.h-receipt-table td {
  border: 1px solid #ddd;
  color: #333;
  padding: 10px;
  text-align: center;
  white-space: nowrap;
}

/* Alternating Row Colors */
.h-receipt-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Hover Effect */
.h-receipt-table tr:hover {
  background-color: #f1f1f1;
  transition: background-color 0.2s ease-in-out;
}

/* No Data Message */
.h-no-data {
  text-align: center;
  color: #888;
  font-style: italic;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .h-navbar {
      padding: 10px 15px;
      flex-direction: column;
      align-items: center;
      text-align: center;
  }

  .h-navbar h1 {
      font-size: 16px;
  }

  .h-search-bar-container {
      width: 100%;
      text-align: center;
      padding: 5px 0;
  }

  .h-search-bar {
      font-size: 0.9rem;
      padding: 6px 10px;
      max-width: 100%;
  }

  .h-receipt-table th,
  .h-receipt-table td {
      padding: 8px;
      font-size: 13px;
  }
}

/* Add these to your Reciepts.css file */
.filters-container {
  margin-bottom: 20px;
}

.filters-row {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  align-items: center;
}

.filter-group {
  display: flex;
  align-items: center;
}

.filter-toggle-button {
  padding: 8px 15px;
  border: 1px solid white;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.filter-toggle-button:hover {
  opacity: 0.9;
}

/* Make the table responsive */
@media (max-width: 1200px) {
  .filters-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .filter-group {
    width: 100%;
  }
  
  .filter-group select,
  .filter-group input {
    width: 100%;
    box-sizing: border-box;
  }
}

@media (max-width: 768px) {
  .h-navbar {
      padding: 10px;
  }

  .h-search-bar {
      width: 100%;
  }

  .h-table-container {
      overflow-x: auto;
  }

  .h-receipt-table {
      font-size: 12px;
      min-width: 500px;
  }
}

@media (max-width: 480px) {
  .h-navbar h1 {
      font-size: 14px;
  }

  .h-search-bar {
      width: 100%;
      font-size: 0.85rem;
  }

  .h-receipt-table {
      font-size: 11px;
      min-width: 400px;
  }

  .h-receipt-table th,
  .h-receipt-table td {
      padding: 6px;
  }
}
