.h-counters-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9f9;

}
.h-counters-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  flex-grow: 1;
  border: 1px solid silver;

}

.h-counters-sidebar {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid silver;

}

.h-counters-sidebar h2 {
  color: #c84347;
  text-align: center;
}

.h-counters-roles-list {
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 10px;
}

.h-counters-tree-node {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  border: 1px solid #ddd;
}

.h-counters-delete-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.h-counters-add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c84347;
  color: white;
  border: none;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background 0.3s;
}

/* Main Content */
.h-counters-main-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #fff;
}

.h-counters-form-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  border: none;
  width: 90%;
  max-width: 500px;
  margin: auto;
}

.h-counters-form-container h3 {
  text-align: center;
  color: #c84347;
}

.h-counters-form-container label {
  display: block;
  font-size: 14px;
  color: #333;
  margin-top: 10px;
}

.h-counters-form-container input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.h-counters-form-container button {
  width: 48%;
  padding: 10px;
  margin-top: 15px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.h-counters-form-container button:first-child {
  background-color: #c84347;
  color: white;
}

.h-counters-form-container button:first-child:hover {
  background-color: #a23338;
}

.h-counters-form-container button:last-child {
  background-color: #ddd;
}

.h-counters-form-container button:last-child:hover {
  background-color: #bbb;
}

/* Media Queries for Responsive Design */
@media screen and (min-width: 768px) {
  .h-counters-container {
    flex-direction: row;
  }
  
  .h-counters-sidebar {
    width: 300px;
    min-width: 250px;
  }
  
  .h-counters-form-container {
    width: 70%;
    border: 2px solid #c84347;
  }
}

@media screen and (max-width: 600px) {
  .h-counters-form-container button {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .h-counters-tree-node {
    border: none;
    border-bottom: 1px solid #eee;
    border-radius: 0;
  }
}