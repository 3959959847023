.h-currencies-roundings-container {
    padding: 20px;
    background-color: #ffffff;
  }
  
  .h-currencies-roundings-container h2 {
    color: #c84347;
    margin-bottom: 1rem;
  }
  
  .h-currencies-roundings-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
  }
  
  .h-currencies-roundings-table th,
  .h-currencies-roundings-table td {
    padding: 10px;
    border: 1px solid #e0e0e0;
    text-align: left;
  }
  
  .h-currencies-roundings-table th {
    background-color: #f5f5f5;
    color: #c84347;
    font-weight: bold;
  }
  
  .h-currencies-roundings-table tbody tr:hover {
    background-color: #f9f9f9;
  }
  
  .h-currencies-roundings-table td {
    color: #666666;
  }

  @media (max-width: 768px) {
    .h-currencies-roundings-container {
        padding: 15px;
    }

    .h-currencies-roundings-container h2 {
        font-size: 1.3rem;
        text-align: center;
    }

    /* Make table scrollable */
    .h-currencies-roundings-container {
        overflow-x: auto;
    }

    .h-currencies-roundings-table {
        display: block;
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    .h-currencies-roundings-table th,
    .h-currencies-roundings-table td {
        padding: 8px;
        font-size: 0.85rem;
    }
}

@media (max-width: 480px) {
    .h-currencies-roundings-container h2 {
        font-size: 1.2rem;
    }

    .h-currencies-roundings-table th,
    .h-currencies-roundings-table td {
        padding: 6px;
        font-size: 0.8rem;
    }
}
  