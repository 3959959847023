.user-defined-fields-container {
    max-height: 80vh; 
    overflow-y: auto; 
    max-width: 600px; 
    margin: 0 auto; 
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #333;
  }
  
  .user-defined-fields-container h2 {
    color: #c84347;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .user-defined-section {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #c84347;
  }
  
  .form-group input[type="text"],
  .form-group input[type="number"],
  .form-group input[type="checkbox"] {
    width: calc(100% - 20px); 
    padding: 10px;
    border: 1px solid #c84347;
    border-radius: 4px;
    background-color: #f9f9f9;
    color: #333;
  }
  
  .form-group input[type="checkbox"] {
    width: auto;
    margin-right: 10px;
  }
  
  input[disabled] {
    background-color: #e6e6e6;
    color: #666;
  }
  
  .user-defined-section:not(:last-child) {
    border-bottom: 1px solid #c84347;
    padding-bottom: 20px;
  }
  
  .user-defined-section:last-child {
    padding-bottom: 0;
  }
  
  .user-defined-section:last-child .form-group {
    margin-bottom: 0;
  }