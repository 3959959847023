/* Currencies.css */
.h-currencies-container {
    display: flex;
    height: 100vh;
    background-color: #ffffff;
    flex-wrap: wrap;
}

/* Sidebar */
.h-currencies-sidebar {
    width: 260px;
    background-color: #ffffff;
    border-right: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
}

.h-currencies-list-container {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
}

.h-currencies-item-container {
    padding: 0.75rem 1rem;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
}

.h-currencies-item-container:hover {
    background-color: #f5f5f5;
}

/* Add Currency Button */
.h-add-currencies-btn {
    margin: 1rem;
    padding: 0.75rem;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

/* Main Content */
.h-currencies-main-content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 300px;
}

/* Responsive Overlay Form */
.h-currencies-form-overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.h-currencies-form-container {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Form Inputs */
.h-currencies-form-container input,
.h-currencies-form-container select {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 1rem;
}

.h-currencies-form-actions-container {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1.5rem;
}

/* Buttons */
.h-currencies-cancel-btn,
.h-currencies-save-btn {
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;
}

.h-currencies-cancel-btn {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
}

.h-currencies-cancel-btn:hover {
    background-color: #f5f5f5;
}

.h-currencies-save-btn {
    color: #ffffff;
    border: none;
}

/* Navbar */
.h-currencies-navbar-container {
    background-color: #ffffff;
    border-bottom: 1px solid #e0e0e0;
    padding: 1rem 2rem;
}

.h-currencies-navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.h-currencies-info-container {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    color: #666666;
    flex-wrap: wrap;
}

.h-currencies-status-indicator {
    padding: 0.25rem 0.75rem;
    border-radius: 999px;
    font-size: 0.875rem;
    background-color: #f5f5f5;
}

/* Sub Navbar */
.h-currencies-sub-navbar-container {
    background-color: #ffffff;
    border-bottom: 1px solid #e0e0e0;
    padding: 0 2rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.h-currencies-sub-nav-tab {
    padding: 1rem 1.5rem;
    cursor: pointer;
    color: #666666;
    font-weight: 500;
    transition: all 0.2s ease;
    border-bottom: 2px solid transparent;
}

/* Page Content */
.h-currencies-page-content-container {
    padding: 1rem;
    background-color: #ffffff;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .h-currencies-container {
        flex-direction: column;
        height: auto;
    }

    .h-currencies-sidebar {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #e0e0e0;
    }

    .h-currencies-main-content-container {
        width: 100%;
    }

    .h-currencies-navbar-container {
        padding: 1rem;
    }

    .h-currencies-form-container {
        width: 95%;
        padding: 1.5rem;
    }
}

@media (max-width: 768px) {
    .h-currencies-navbar-content {
        flex-direction: column;
        align-items: flex-start;
    }

    .h-currencies-info-container {
        gap: 1rem;
    }

    .h-currencies-sub-navbar-container {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        padding: 1rem;
    }

    .h-currencies-sub-nav-tab {
        padding: 0.75rem 1rem;
        width: 100%;
        text-align: left;
    }
}

@media (max-width: 480px) {
    .h-currencies-container {
        height: auto;
    }

    .h-currencies-sidebar {
        display: none;
    }

    .h-currencies-form-container {
        width: 100%;
        padding: 1rem;
    }

    .h-currencies-navbar-container {
        padding: 0.75rem;
    }

    .h-currencies-sub-nav-tab {
        font-size: 0.875rem;
        padding: 0.5rem;
    }
}
